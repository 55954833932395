import {useRouter} from "next/router";
import Link from "next/link";

export const Footer = () => {
    const router = useRouter();

    return (
        <footer className={'flex-shrink-0 bg-green w-full p-5'}>
            <div className={'max-w-screen-lg mx-auto'}>
                <div className={'md:flex justify-between'}>
                    <p className={'text-white font-normal mb-3 md:mb-0'}>
                        <a href={'https://www.fruitboeraanhuis.nl/'} className={'font-medium tracking-wide hover:underline'}>
                            Fruitboer Aan Huis</a> –
                        Deventerseweg 3 –
                        2994 LE Barendrecht
                    </p>
                    {(!router.asPath.includes('wijzig') && !router.asPath.includes('cancel')) ?
                        <Link href='/wijzig'>
                            <a className={'text-white font-medium hover:underline'}>Wijzig abonnement</a>
                        </Link>
                        :
                        <Link href='/samenstellen'>
                            <a className={'text-white font-medium hover:underline'}>Bestel fruitbox</a>
                        </Link>
                    }

                </div>
            </div>
        </footer>
    )
}
