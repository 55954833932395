import type {AppProps} from 'next/app';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/700.css';
import 'tailwindcss/tailwind.css';
import 'react-day-picker/dist/style.css';
import {Footer} from "../components/organisms/Footer";
import {RecoilRoot} from "recoil";
import {useEffect} from "react";
import TagManager from "react-gtm-module"
import {MessageTemplate} from "../components/templates/messageTemplate";
import '../assets/css/styles.css'

function MyApp({Component, pageProps}: AppProps) {
    useEffect(() => {
        TagManager.initialize({gtmId: String(process.env.NEXT_PUBLIC_TAG_MANAGER_ID)})
    }, []);

    return (
        <RecoilRoot>
            <div className={'flex flex-col min-h-screen'}>
                <div className={'flex-grow'}>
                    {process.env.NEXT_PUBLIC_MAINTENANCE === "1" ?
                            <MessageTemplate
                                title={'Maintenance'}
                                description={'Vanwege een update is de shop tijdelijk niet bereikbaar, binnen 5 min zullen we weer online zijn.'}
                            ><></>
                        </MessageTemplate>
                        : <Component {...pageProps} />
                    }
                </div>
                <Footer/>
            </div>
        </RecoilRoot>
    );
}

export default MyApp
