import {FC, ReactChild} from "react";

interface MessageTemplateProps{
    children?: ReactChild,
    title: string,
    description: string
}

export const MessageTemplate: FC<MessageTemplateProps> = ({children, title, description}) => (
    <div className='text-left md:text-center mt-[25vh] mx-auto max-w-[1000px] p-5'>
        <h1 className='text-black font-bold text-40 md:text-[75px] mb-6'>{title}</h1>
        <p className={'text-gray text-[18px] mb-8 max-w-[800px] mx-auto'} dangerouslySetInnerHTML={{__html: description}}/>
        {children}
    </div>
)